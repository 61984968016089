import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide83/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide83/image/img.png"
import slider1 from "@components/pageGuide/guides/guide83/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide83/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide83/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Banca y finanzas  ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      6 pasos para optimizar la comunicación en servicios financieros
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      La comunicación es clave para mantener y fortalecer la relación con clientes. Identifica los canales adecuados para cada tipo de mensaje y optimiza su uso. Personaliza la información para cada segmento y mejora la experiencia del usuario.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Centralización de canales con una plataforma omnicanal.",
      },
      {
        key: 2,
        text: "Segmentación de clientes para mensajes personalizados."
      },
      {
        key: 3,
        text: "Automatización de recordatorios y notificaciones clave.",
      },
      {
        key: 4,
        text: "Uso de chatbots para consultas frecuentes y soporte 24/7.",
      },
      {
        key: 5,
        text: "Seguimiento de casos y solicitudes con flujos automatizados.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo estructurar mensajes claros y oportunos en cada interacción. Asegúrate de utilizar herramientas que permitan automatizar y agilizar los procesos de respuesta.
          <br /><br />
          Por último, descubre más detalles, ejemplos prácticos y estrategias recomendadas descargándola. Mejora la comunicación y la eficiencia en cada contacto.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
